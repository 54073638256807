
<div class="pam-wrapper mt-5 pb-4">
    <div class="container">
        <div class="row boxes-home-pam mb-3 mb-lg-5 d-none d-lg-flex" *ngIf="portalData">
            <div class="col-12 col-lg-3 pe-lg-4 mb-2 mb-lg-0">
                <div class="bg-white box rounded p-3 d-flex flex-column ">
                    <p class="number mb-2">{{this.portalData.percent.toFixed(2)}}%</p>
                    <div class="d-flex align-items-center">
                        <p class="title mb-0 me-3 " [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.boxProgres' | translate}}</p>
                        <p-progressBar class="w-100" [value]="this.portalData.percent"></p-progressBar>
                    </div>



                </div>
            </div>
            <div class="col-12 col-lg-3 px-lg-4 mb-2 mb-lg-0">
                <div class="bg-white box rounded p-3 d-flex flex-column align-items-center">
                    <p class="number mb-2">{{this.portalData.nAxes}}</p>
                    <p class="title mb-0 " [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.boxAxes' |translate}}</p>

                </div>
            </div>
            <div class="col-12 col-lg-3 px-lg-4 mb-2 mb-lg-0">
                <div class="bg-white box rounded p-3 d-flex flex-column align-items-center">
                    <p class="number mb-2">{{this.portalData.nActions}}</p>
                    <p class="title mb-0" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.boxActions' | translate}}</p>

                </div>
            </div>
            <div class="col-12 col-lg-3 ps-lg-4 mb-2 mb-lg-0">
                <div class="bg-white box rounded p-3 d-flex flex-column align-items-center">
                    <p class="number mb-2">{{this.portalData.nActuactions}}</p>
                    <p class="title mb-0" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.boxActuations' | translate}}</p>
                </div>
            </div>

        </div>
        <p-carousel *ngIf="portalData"
                class="d-flex d-lg-none mb-4"
                [value]="boxes"
                [numVisible]="1"
                    [circular]="true"
        >
            <ng-template let-box pTemplate="item">
                <div class="bg-white box rounded p-3 d-flex flex-column " *ngIf="box.progressBar">
                    <p class="number mb-2">{{box.value.toFixed(2)}}%</p>

                    <div class="d-flex align-items-center">
                        <p class="title mb-0 me-3 " [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{box.text}}</p>
                        <p-progressBar class="w-100" [value]="box.value"></p-progressBar>
                    </div>
                </div>
                <div class="bg-white box rounded p-3 d-flex flex-column align-items-center" *ngIf="!box.progressBar">
                    <p class="number mb-2">{{box.value}}</p>
                    <p class="title mb-0 " [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{box.text}}</p>

                </div>
            </ng-template>
        </p-carousel>
        <div class="row mb-5" *ngIf="!portalData">
                <div class="col-3">
                    <p-skeleton  styleClass="mb-2"  height="120px"></p-skeleton>
                </div>
                <div class="col-3">
                    <p-skeleton  styleClass="mb-2"  height="120px"></p-skeleton>
                </div>
                <div class="col-3">
                    <p-skeleton  styleClass="mb-2"  height="120px"></p-skeleton>
                </div>
                <div class="col-3">
                    <p-skeleton  styleClass="mb-2"  height="120px"></p-skeleton>
                </div>
        </div>
        <p-dropdown *ngIf="selectedMandate"
                [options]="filters"
                [(ngModel)]="selectedFilter"
                optionLabel="name"/>
        <p-skeleton  *ngIf="!selectedMandate" styleClass="mb-4" width="150px" height="50px"></p-skeleton>

        <p-skeleton  *ngIf="!selectedMandate" styleClass="mb-2" height="200px"></p-skeleton>
        <p-skeleton  *ngIf="!selectedMandate" styleClass="mb-2" height="200px"></p-skeleton>
        <p-skeleton  *ngIf="!selectedMandate" styleClass="mb-2"  height="200px"></p-skeleton>


        <div class="mt-4" *ngIf="this.selectedFilter.id === 0">
            <div *ngIf="!selectedMandate?.axes.length && !loading">
                    <div>
                       <app-empty-state [text]="'PAM.emptyActions' | translate"></app-empty-state>
                    </div>
            </div>
            <p-accordion (activeIndexChange)="activeIndexChange($event)" [activeIndex]="activeIndex">
                <p-accordionTab *ngFor="let axe of selectedMandate?.axes; let i = index">
                    <ng-template pTemplate="header"  >
                        <div class="w-100 row-axe d-flex flex-column"   [ngClass]="activeIndex === i ? 'expanded' : ''">
                            <div class="d-flex flex-column flex-md-row">
                                <div class="img-box position-relative" [style.background-image]="'url(' + (axe?.image | imageUrl) + ')'">
                                    <i class="fa position-absolute p-2 cursor-pointer text-white" *ngIf="axe?.actions.length" [ngClass]="activeIndex === i ? 'fa-minus' : 'fa-plus'"
                                       [style.background-color]="community?.color1 ? community.color1 : '#17A1FA'">
                                    </i>
                                </div>
                                <div class="w-70 p-4 d-flex flex-column justify-content-between">
                                    <div>
                                        <p class="mb-2" style="font-weight: 600" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">Eix {{i + 1}}</p>
                                        <h5 class="mt-2 mb-3 mt-md-0 mb-md-0" >{{axe.name}}</h5>
                                    </div>
                                    <div class="d-flex align-items-md-center justify-content-between row-ods-and-progress">
                                        <div class="d-flex align-items-center row-progress">
                                            <div *ngFor="let ods of axe.ods?.slice(0, 3)" class="d-flex align-items-center">
                                                <img class="me-2" src="assets/icons/ODSPAM/{{ods.image}}" style="width: 35px" />
                                            </div>
                                            <span *ngIf="axe.ods?.length > 3" tooltipStyleClass="ods-tooltip" class="border p-2 rounded font-w-600" [pTooltip]="remainingOdsTooltip">
                                                +{{ axe.ods.length - 3 }}
                                            </span>
                                        </div>

                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 me-3">{{this.axe.progress.toFixed(2)}}%</p>
                                            <p-progressBar [value]="axe.progress"></p-progressBar>
                                        </div>
                                        <ng-template #remainingOdsTooltip>
                                            <div class="d-flex align-items-center px-3 py-1 text-dark" *ngFor="let ods of axe.ods?.slice(3)">
                                                <img src="assets/icons/ODSPAM/{{ods.image}}" style="width: 35px" />
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p-accordion (activeIndexChange)="activeIndexChangeActions($event)" [activeIndex]="activeIndexActions" >
                            <p-accordionTab *ngFor="let action of axe.actions; let j = index" [disabled]="action?.actuations?.length === 0">
                                <ng-template pTemplate="header">
                                    <div class="p-4 row-actions w-100" >
                                        <div class="row align-items-center justify-content-between">
                                            <span class="d-flex d-md-none col-12 col-md-2 mt-2 mt-md-0 mb-2" style="font-weight: 600; font-size: 14px"
                                                  [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
                                                <span *ngIf="action?.actuations?.length !== 0" >{{action?.actuations?.length}}</span>
                                                <span class="ms-1" *ngIf="action?.actuations?.length == 1"> actuació</span>
                                                <span class="ms-1 text-lowercase" *ngIf="action?.actuations?.length > 1"> {{'PAM.actuations' | translate}}</span>
                                                <span  *ngIf="action?.actuations?.length === 0"> Aquesta acció no te actuacions</span>
                                            </span>
                                            <div class="col-12 col-md-6 d-flex align-items-center">
                                                <i class="fa p-2 cursor-pointer text-white me-3 icon-fix d-none d-md-block" [ngClass]="activeIndexActions === j ? 'fa-minus' : 'fa-plus'"
                                                   [style.background-color]="community?.color1 ? community.color1 : '#17A1FA'">
                                                </i>
                                                <p class="mb-0 fw-500">{{action.name}}</p>
                                            </div>
                                            <span class="d-none d-md-flex col-12 col-md-2 mt-2 mt-md-0" style="font-weight: 600" [style.color]="community?.color1 ? community.color1 : '#17A1FA'">
                                               <span *ngIf="action?.actuations?.length !== 0" >{{action?.actuations?.length}}</span>
                                                <span class="ms-1" *ngIf="action?.actuations?.length == 1"> actuació</span>
                                                <span class="ms-1 text-lowercase" *ngIf="action?.actuations?.length > 1"> {{'PAM.actuations' | translate}}</span>
                                                <span  *ngIf="action?.actuations?.length === 0"> Aquesta acció no te actuacions</span>
                                            </span>
                                            <div class="d-flex col-12 col-md-2 my-3 my-md-0">
                                                <span class="me-2">{{action.progress.toFixed(2)}}%</span>
                                                <p-progressBar [value]="action.progress"></p-progressBar>
                                            </div>
                                            <div class="d-flex col-12 col-md-2 justify-content-between justify-content-md-end link-detail">
                                                <a class="text-underline text-dark" (click)="goDetail(action.id , $event);">
                                                    <i class="fa fa-eye me-2"></i>
                                                    <span>+ info</span>
                                                </a>
                                                <i class="fa p-2 cursor-pointer text-white icon-fix d-block d-md-none" [ngClass]="activeIndexActions === j ? 'fa-minus' : 'fa-plus'"
                                                   [style.background-color]="community?.color1 ? community.color1 : '#17A1FA'"
                                                [style.display]="action?.actuations?.length === 0 ? 'none!important':''">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <p-table [value]="action.actuations" class="table-actuations">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.status' | translate}}</th>
                                                <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.actuations' | translate}}</th>
                                                <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.date' | translate}}</th>
                                                <th [style.color]="community?.color1 ? community.color1 : '#17A1FA'">{{'PAM.progress' | translate}}</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-actuation>
                                            <tr>
                                                <td><app-status-color [percent]="actuation.percent"></app-status-color></td>
                                                <td class="fw-500">{{ actuation.name }}</td>
                                                <td>{{ actuation.date | date: 'dd/MM/yyyy' }}</td>
                                                <td>{{ actuation.percent.toFixed(2) }}%</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-accordionTab>
                        </p-accordion>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>

        </div>
        <div class="mt-4 areas-box" *ngIf="selectedFilter.id === 1" >
            <div class="row">
                <div class="col-md-6 col-lg-3 col-12 mb-4 " *ngFor="let area of areas; let i = index" [ngClass]="i % 4 == 2 || i % 4 == 1 ? 'px-lg-4' : i % 4 == 0? 'pe-lg-4' : 'ps-lg-4' " >
                    <div class="w-100 p-3 card-area cursor-pointer " (click)="linkTodetail('area' , area.id)"
                         [style.background-color]="getColorWithOpacity(area.color , 0.2)"
                        [style.color]="area.color">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class=" d-flex align-items-center">
                                  <app-convert-svg [currentColor]="area.color" [path]="area.path | imageUrl"></app-convert-svg>
                                <span class=" ms-3 fw-600">{{area.name}}</span>
                            </div>


                        </div>
                        <p class="mb-0 fw-600 number">{{area.progress.toFixed(2)}}%</p>
                        <p-progressBar class="w-100" [value]="area.progress"  [style]="{'--progress-color': area.color}"></p-progressBar>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <span class=" d-block fw-500"> + info <i class="fa-solid fa-circle-arrow-right"></i></span>
                            <span class="status px-2 py-1 rounded text-white" [ngClass]="area.progress === 0 ? 'red' : area.progress === 100 ? 'green' : 'yellow'">
                                    <span *ngIf="area.progress === 0"> {{'PAM.notStarted' | translate}}</span>
                                    <span *ngIf="area.progress === 100"> {{'PAM.completed' | translate}}</span>
                                    <span *ngIf="area.progress > 0 && area.progress < 100"> {{'PAM.inProgress' | translate}}</span>
                                </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="mt-4 areas-box" *ngIf="selectedFilter.id === 2" >
            <div class="row">
                <div class="col-md-6 col-lg-3 col-12 mb-4" *ngFor="let area of tipus; let i = index" [ngClass]="i % 4 == 2 || i % 4 == 1 ? 'px-lg-4' : i % 4 == 0? 'pe-lg-4' : 'ps-lg-4' " >
                    <div class="w-100 p-3 card-area cursor-pointer" (click)="linkTodetail('type' , area.id)"
                         [style.background-color]="getColorWithOpacity(area.color , 0.2)"
                         [style.color]="area.color">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class=" d-flex align-items-center">
                                <app-convert-svg [currentColor]="area.color" [path]="area.path | imageUrl"></app-convert-svg>
                                <span class=" ms-3 fw-600">{{area.name}}</span>
                            </div>


                        </div>
                        <p class="mb-0 fw-600 number">{{area.progress.toFixed(2)}}%</p>
                        <p-progressBar class="w-100" [value]="area.progress"  [style]="{'--progress-color': area.color}"></p-progressBar>

                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <span class=" d-block fw-500"> + info <i class="fa-solid fa-circle-arrow-right"></i></span>
                            <span class="status px-2 py-1 rounded text-white" [ngClass]="area.progress === 0 ? 'red' : area.progress === 100 ? 'green' : 'yellow'">
                                    <span *ngIf="area.progress === 0"> {{'PAM.notStarted' | translate}}</span>
                                    <span *ngIf="area.progress === 100"> {{'PAM.completed' | translate}}</span>
                                    <span *ngIf="area.progress > 0 && area.progress < 100"> {{'PAM.inProgress' | translate}}</span>
                                </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="mt-4" *ngIf="selectedFilter.id === 3" >
            <div class="row">
                <div class="col-6 col-md-4 col-lg-2 mb-5 px-4 cursor-pointer " (click)="linkTodetail('ods' , od.id)" *ngFor="let od of ods; let i = index">
                    <div class="water-fill-container bg-white rounded ">
                        <img class="rounded fa-file-image" width="100%" src="assets/icons/ODSPAM_INVERTED/ODS_{{i+1}}.png" />
                        <div class="wave" [style.top]="(100 - od.progress) + '%'" [style.background-color]="getColorWithOpacity(od.color , 0.2)"></div>

                    </div>
                    <p class="number-ods mb-0" [style.color]="od.color"> {{od.progress.toFixed(2)}}%</p>
                    <span class="mt-0 d-block fw-500"> + info <i class="fa-solid fa-circle-arrow-right"></i></span>
                </div>
            </div>
        </div>



    </div>

</div>

